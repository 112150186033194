<template>
	<PanelsDefault :open="property.panelOpen" @close="property.setPanel(false)">
		<template v-if="property.currentRecord?.id">
			<div class="pb-1 sm:pb-6">
				<div>
					<div v-if="showImg" class="relative h-40 sm:h-56">
						<img id="property-google-maps-img" class="absolute h-full w-full object-cover" :src="googleMapsImg" alt="" @load="loadedImg">
					</div>
					<div class="mt-6 px-4 sm:flex sm:items-end sm:px-6">
						<div class="sm:flex-1">
							<div>
								<div class="flex items-center">
									<h3 class="text-xl font-obviously-regular font-semibold tracking-tighter text-jmi-purple1 sm:text-2xl mb-2">
										{{ property.currentRecord.address ?? property.currentRecord.full_address }}
									</h3>
								</div>
								<a :href="googleMapsUrl" target="_blank" class="text-xs text-jmi-purple3 flex items-center">
									Open in Google Maps
									<ArrowTopRightOnSquareIcon class="flex-shrink-0 ml-1.5 h-3 w-3 " aria-hidden="true" />
								</a>
							</div>
							<div class="mt-6 flex flex-wrap space-y-3 sm:space-y-0 sm:space-x-3">
								<FormsButton color="green" dusk="start-movein" @click.prevent="property.propertyStartMove('in')">
									Start move-in
								</FormsButton>
								<FormsButton color="greenOutline" dusk="start-moveout" @click.prevent="property.propertyStartMove('out')">
									Start move-out
								</FormsButton>
								<FormsButton color="greenOutline" dusk="edit-property" @click.prevent="property.propertyEdit()">
									<PencilIcon class="h-5 w-5 stroke-1" aria-hidden="true" />
								</FormsButton>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="px-4 pt-5 pb-5 sm:px-0 sm:pt-0">
				<dl class="space-y-8 sm:space-y-0 sm:divide-y sm:divide-jmi-purple4">
					<div class="sm:flex sm:px-6 sm:py-5">
						<dt class="text-sm font-medium text-jmi-purple2 sm:w-40 sm:flex-shrink-0 lg:w-48">
							Status
						</dt>
						<dd class="mt-1 text-sm text-jmi-purple1 sm:col-span-2 sm:mt-0 sm:ml-6">
							<div class="flex items-center justify-between">
								<p class="text-sm">
									{{ property.currentRecord.managed ? 'Managed' : 'Non-managed' }}
								</p>
							</div>
						</dd>
					</div>
					<div class="sm:flex sm:px-6 sm:py-5">
						<dt class="text-sm font-medium text-jmi-purple2 sm:w-40 sm:flex-shrink-0 lg:w-48">
							UPRN
						</dt>
						<dd class="mt-1 text-sm text-jmi-purple1 sm:col-span-2 sm:mt-0 sm:ml-6">
							<div class="flex items-center justify-between">
								<p class="text-sm">
									{{ property.currentRecord.uprn ?? "-" }}
								</p>
							</div>
						</dd>
					</div>

					<div v-for="supplier in property.currentRecord.suppliers" :key="supplier.id">
						<div v-if="supplier.type !== 'Gas' && supplier.type !== 'Electricity'" class="sm:flex sm:px-6 sm:py-5">
							<dt class="text-sm font-medium text-jmi-purple2 sm:w-40 sm:flex-shrink-0 lg:w-48">
								{{ supplier.type }}
							</dt>
							<dd class="mt-1 text-sm text-jmi-purple1 sm:col-span-2 sm:mt-0 sm:ml-6">
								<div class="flex items-center justify-between">
									<p class="text-sm">
										{{ supplier.name }}
									</p>
								</div>
							</dd>
						</div>
					</div>

					<div class="sm:flex sm:px-6 sm:py-5">
						<dt class="text-sm font-medium text-jmi-purple2 sm:w-40 sm:flex-shrink-0 lg:w-48">
							Gas at property
						</dt>
						<dd class="mt-1 text-sm text-jmi-purple1 sm:col-span-2 sm:mt-0 sm:ml-6">
							<div class="flex items-center justify-between">
								<p class="text-sm">
									{{ property.currentRecord.gas_at_property ? 'Yes' : 'No' }}
								</p>
							</div>
						</dd>
					</div>
				</dl>
			</div>
		</template>
		<template v-else>
			<Spinner class="w-24 my-56 mx-auto block" />
		</template>
	</PanelsDefault>
</template>
<script setup lang="ts">
import {useRuntimeConfig} from "#app";
import {
	ArrowTopRightOnSquareIcon,
} from '@heroicons/vue/24/outline';
import {
	PencilIcon,
} from '@heroicons/vue/24/solid'
import {computed, ref} from "vue";
import {useProperties} from "~/stores/properties";
const property = useProperties();

const runtimeConfig = useRuntimeConfig();

const googleMapsImg = computed(() => {
	let key = runtimeConfig.public["googleApiKey"];
	let address = property.currentRecord.full_address;
	return `https://maps.googleapis.com/maps/api/staticmap?markers=${address}&size=448x224&scale=2&key=${key}`;
});

const googleMapsUrl = computed(() => {
	let address = property.currentRecord.full_address;
	return `https://www.google.com/maps/search/?api=1&query=${address}`;
});

const showImg = ref(true);

const loadedImg = () => {
	let img = document.getElementById('property-google-maps-img') as HTMLImageElement;
	img.crossOrigin = "Anonymous";
	let canvas = document.createElement('canvas');
	canvas.width = img.width;
	canvas.height = img.height;
	canvas.getContext('2d').drawImage(img, 0, 0, img.width, img.height);
	let pixelData = canvas.getContext('2d').getImageData(290, 2, 1, 1).data.join(",");
	if (pixelData === "249,238,188,255") {
		showImg.value = false;
	}
};
</script>
