<template>
	<template>
		<ModalsPlain :open="open" @close="$emit('close')">
			<div class="px-4 pt-5 pb-4 sm:p-6">
				<div class="bg-jmi-purple4 mx-auto flex items-center justify-center h-12 w-12 rounded-full">
					<BuildingOffice2Icon class="h-6 w-6 text-jmi-purple1 stroke-2" aria-hidden="true" />
				</div>
				<div class="mt-3 sm:mt-5">
					<DialogTitle as="h3" class="mb-8 text-lg text-center leading-6 font-medium text-jmi-purple1">
						Select Branch
					</DialogTitle>
					<FormsSelect
						v-model="selectedBranch"
						type="listbox"
						name="branch"
						placeholder="All Branches"
						:options="availableBranches"
						:hide-chevron-up-down-icon="true"
					/>
				</div>
			</div>
		</ModalsPlain>
	</template>
</template>

<script setup lang="ts">
import { DialogTitle } from '@headlessui/vue'
import { computed } from "vue";
import { BuildingOffice2Icon } from '@heroicons/vue/24/outline';
import { useSession } from "~/stores/session";

const session = useSession();

defineProps({
	open: {
		type: Boolean,
		required: true
	}
});

const emit = defineEmits(['close']);

const availableBranches = [{ id: null, label: "All Branches" }, ...session.allowed_partners];
const selectedBranch = computed({
	get() {
		return session.activePartnerId;
	},
	set(value) {
		session.setActivePartnerId(value ?? undefined);
		emit('close');
	}
});

</script>
