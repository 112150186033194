<template>
	<div dusk="layout">
		<TransitionRoot as="template" :show="sidebarOpen">
			<Dialog as="div" class="relative z-40 lg:hidden" @close="closeMobileNav">
				<TransitionChild
					as="template"
					enter="transition-opacity ease-linear duration-300"
					enter-from="opacity-0"
					enter-to="opacity-100"
					leave="transition-opacity ease-linear duration-300"
					leave-from="opacity-100"
					leave-to="opacity-0"
				>
					<div class="fixed inset-0 bg-jmi-purple2 bg-opacity-75" />
				</TransitionChild>

				<div class="fixed inset-0 flex z-40">
					<TransitionChild
						as="template"
						enter="transition ease-in-out duration-300 transform"
						enter-from="-translate-x-full"
						enter-to="translate-x-0"
						leave="transition ease-in-out duration-300 transform"
						leave-from="translate-x-0"
						leave-to="-translate-x-full"
					>
						<DialogPanel dusk="mobile-sidebar" class="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-jmi-purple1">
							<TransitionChild
								as="template"
								enter="ease-in-out duration-300"
								enter-from="opacity-0"
								enter-to="opacity-100"
								leave="ease-in-out duration-300"
								leave-from="opacity-100"
								leave-to="opacity-0"
							>
								<div class="absolute top-0 right-0 -mr-12 pt-2">
									<button type="button" class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-1 focus:ring-inset focus:ring-white" @click="closeMobileNav">
										<span class="sr-only">Close sidebar</span>
										<XMarkIcon class="h-6 w-6 stroke-1 text-white" aria-hidden="true" />
									</button>
								</div>
							</TransitionChild>
							<div class="flex-shrink-0 flex items-center px-4">
								<HeaderMenuLogo />
							</div>
							<div class="mt-5 flex-1 flex flex-col">
								<HeaderMenuItems :navigation="navigation" @link-click="closeMobileNav" />
								<HeaderMenuFooter
									:selected-branch="selectedBranch"
									:has-multiple-branches="hasMultipleBranches"
									:fixed-position="true"
									@open-branch-modal="openBranchModal"
								/>
							</div>
						</DialogPanel>
					</TransitionChild>
					<div class="flex-shrink-0 w-14" aria-hidden="true">
						<!-- Dummy element to force sidebar to shrink to fit close icon -->
					</div>
				</div>
			</Dialog>
		</TransitionRoot>

		<!-- Static sidebar for desktop -->
		<div class="hidden lg:flex lg:w-56 lg:flex-col lg:fixed lg:inset-y-0">
			<div class="flex flex-col flex-grow pt-5 bg-jmi-purple1 overflow-y-auto">
				<div class="flex items-center flex-shrink-0 px-4">
					<HeaderMenuLogo />
				</div>
				<div class="mt-5 flex-1 flex flex-col">
					<HeaderMenuItems :navigation="navigation" @link-click="clearSearch" />
					<HeaderMenuFooter
						:selected-branch="selectedBranch"
						:has-multiple-branches="hasMultipleBranches"
						@open-branch-modal="openBranchModal"
					/>
				</div>
			</div>
		</div>
		<div class="lg:pl-56 flex flex-col flex-1">
			<div class="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white shadow">
				<button type="button" dusk="open-sidebar-button" class="px-4 border-r border-jmi-purple4 text-jmi-purple2 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-jmi-purple3 lg:hidden" @click="sidebarOpen = true">
					<span class="sr-only">Open sidebar</span>
					<Bars3BottomLeftIcon class="h-6 w-6 stroke-1" aria-hidden="true" />
				</button>
				<div class="flex-1 px-4 flex justify-end">
					<div v-if="showSearchInput" class="flex-1 flex">
						<form class="w-full flex md:ml-0" action="#" method="GET">
							<label for="search-field" class="sr-only">Search</label>
							<div class="relative w-full text-jmi-purple3 focus-within:text-jmi-purple2">
								<div class="absolute inset-y-0 left-0 flex items-center pointer-events-none">
									<MagnifyingGlassIcon class="h-5 w-5 stroke-2" aria-hidden="true" />
								</div>
								<input
									id="search-field"
									v-model="searchRef"
									class="block w-full h-full pl-8 pr-3 py-2 border-transparent placeholder-jmi-purple3 focus:outline-none focus:placeholder-jmi-purple3 focus:ring-0 focus:border-transparent sm:text-sm"
									placeholder="Search"
									type="search"
									name="search"
								>
							</div>
						</form>
					</div>
					<div :class="['flex items-center', {'ml-4 md:ml-6': showSearchInput}]">
						<div v-if="showSearchInput" class="mr-3" />

						<FormsButton to="/moves/add" color="green">
							<PlusIcon class="ml-0 md:-ml-1 mr:0 md:mr-2 h-5 w-5 stroke-1" aria-hidden="true" />
							<span class="hidden md:inline">Add move</span>
						</FormsButton>

						<!-- Profile dropdown -->
						<Menu as="div" class="ml-3 relative">
							<div>
								<MenuButton class="max-w-xs bg-jmi-coral rounded-full flex items-center text-sm focus:outline-none lg:p-2 lg:hover:bg-jmi-purple4" dusk="profile-dropdown">
									<span class="hidden ml-3 text-jmi-purple2 text-sm font-medium lg:block"><span class="sr-only">Open user menu for </span>{{ session.user?.full_name }}</span>
									<ChevronDownIcon class="hidden flex-shrink-0 ml-1 h-5 w-5 text-jmi-purple2 stroke-1 lg:block" aria-hidden="true" />
									<UsersIcon class="flex-shrink-0 rounded-full h-5 w-5 text-jmi-purple2 stroke-1 lg:hidden" aria-hidden="true" />
								</MenuButton>
							</div>
							<transition
								enter-active-class="transition ease-out duration-100"
								enter-from-class="transform opacity-0 scale-95"
								enter-to-class="transform opacity-100 scale-100"
								leave-active-class="transition ease-in duration-75"
								leave-from-class="transform opacity-100 scale-100"
								leave-to-class="transform opacity-0 scale-95"
							>
								<MenuItems class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-jmi-purple5 focus:outline-none">
									<div class="py-1">
										<MenuItem v-slot="{ active }">
											<a href="https://justmoveinportal.crisp.help/en" target="_blank" :class="[active ? 'bg-jmi-purple5' : '', 'block px-4 py-2 text-sm text-jmi-purple2']">Help</a>
										</MenuItem>
									</div>
									<div class="py-1">
										<MenuItem v-if="session.user?.partner_admin" v-slot="{ active }">
											<NuxtLink href="/partner-users" :class="[active ? 'bg-jmi-purple5 text-jmi-purple1' : 'text-jmi-purple2', 'block px-4 py-2 text-sm manage-users']">
												Manage Users
											</NuxtLink>
										</MenuItem>
										<MenuItem v-slot="{ active }">
											<a href="#" :class="[active ? 'bg-jmi-purple5 text-jmi-purple1' : 'text-jmi-purple2', 'block px-4 py-2 text-sm']" @click.prevent="openPasswordModal">Edit Password</a>
										</MenuItem>
										<MenuItem v-if="session.user?.partner_admin" v-slot="{ active }">
											<a href="#" :class="[active ? 'bg-jmi-purple5 text-jmi-purple1' : 'text-jmi-purple2', 'block px-4 py-2 text-sm']" @click.prevent="openApiModal">API Keys</a>
										</MenuItem>
									</div>
									<div class="py-1">
										<MenuItem v-slot="{ active }">
											<a href="https://justmovein.com/agencies-and-businesses/partner-toolkit" target="_blank" :class="['flex items-center', active ? 'bg-jmi-purple5 text-jmi-purple1' : 'text-jmi-purple2', 'block px-4 py-2 text-sm']">
												<span class="flex-grow">Partner Toolkit</span>
												<ArrowTopRightOnSquareIcon class="flex-shrink-0 ml-1.5 h-3 w-3 text-jmi-purple3" aria-hidden="true" />
											</a>
										</MenuItem>
										<MenuItem v-slot="{ active }">
											<a href="https://justmovein.com/agencies-and-businesses/integration-instructions" target="_blank" :class="['flex items-center', active ? 'bg-jmi-purple5 text-jmi-purple1' : 'text-jmi-purple2', 'block px-4 py-2 text-sm']">
												<span class="flex-grow">Integrations</span>
												<ArrowTopRightOnSquareIcon class="flex-shrink-0 ml-1.5 h-3 w-3 text-jmi-purple3" aria-hidden="true" />
											</a>
										</MenuItem>
									</div>
									<div class="py-1">
										<MenuItem v-slot="{ active }" dusk="logout-button">
											<a href="#" dusk="logout-button" :class="[active ? 'bg-jmi-purple5' : '', 'block px-4 py-2 text-sm text-jmi-purple2']" @click.prevent="logout">
												<template v-if="isLoggingOut">
													Logging out...
												</template>
												<template v-else>
													Sign Out
												</template>
											</a>
										</MenuItem>
									</div>
								</MenuItems>
							</transition>
						</Menu>
					</div>
				</div>
			</div>

			<main class="pt-5 sm:pt-8 2xl:pt-14 pb-20 bg-jmi-purple5">
				<div v-if="searchRef">
					<Search v-model="searchRef" />
				</div>
				<div v-else>
					<slot />
				</div>
			</main>
		</div>
		<ModalsBranch :open="isBranchModalOpen" @close="closeBranchModal" />
		<ModalsApi :open="isApiModalOpen" @close="closeApiModal" />
		<ModalsEditPassword :open="isPasswordModalOpen" @close="closePasswordModal" />
		<PropertiesPanel />
		<MoversPanel />
	</div>
</template>

<script setup lang="ts">
import {useSession} from "~/stores/session";
import {ref, onMounted, watch, computed} from 'vue';
import {
	Dialog,
	DialogPanel,
	Menu,
	MenuButton,
	MenuItem,
	MenuItems,
	TransitionChild,
	TransitionRoot,
} from '@headlessui/vue';
import {
	ClipboardDocumentListIcon,
	HomeIcon,
	Bars3BottomLeftIcon,
	PlusIcon,
	ArrowTopRightOnSquareIcon,
	MagnifyingGlassIcon,
	UsersIcon,
	ArrowsRightLeftIcon,
	RectangleGroupIcon,
	UserGroupIcon,
	XMarkIcon,
	ChevronDownIcon
} from '@heroicons/vue/24/outline';
import {useCoos} from "~/stores/coos";
import {useHead, useState} from "#app";
import {useMoves} from "~/stores/moves";
import {definePageMeta} from "#imports";
import {useRuntimeConfig} from "#app";
import type {NavigationMenuItem} from "~/utils/types";

const session = useSession();
const coos = useCoos();
const moves = useMoves();
const config = useRuntimeConfig();

// Disable eslint for this line because it's a runtime hook
// eslint-disable-next-line no-undef
useHead({
	title: 'Just Move In - Partner Portal',
	viewport: 'width=device-width, initial-scale=1, maximum-scale=1',
	charset: 'utf-8',
	meta: [
		{name: 'description', content: 'Just Move In - Partner Portal'}
	],
	bodyAttrs: {
		class: 'h-full bg-jmi-purple5'
	},
	htmlAttrs: {
		class: 'h-full bg-jmi-purple1'
	}
})

const showSearchInput = ref(false);

const clearSearch = () => {
	searchRef.value = "";
};

const isPasswordModalOpen = ref(false);
const isApiModalOpen = ref(false);
const isBranchModalOpen = ref(false);

coos.boot('global', {
	sort: {"scheduled":"asc"},
	queryFacets: {
		countable_errors: "supplierid",
	}
});

// This is checked by Dusk tests, do not remove.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const cooCountFetchedAt = computed(() => {
	return coos.instances['global']?.countFetchedAt;
});

// This is checked by Dusk tests, do not remove.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const currentParentPartnerId = computed(() => {
	return session.user?.active_parent_partner_id;
});

moves.boot('global', {
	sort: {"movein.movedate":"desc"},
	queryFacets: {
		is_pending: true
	}
});

const computeNavigation = (): NavigationMenuItem[] => {
	return [
		{name: 'Dashboard', slug: 'dashboard', href: '/', icon: RectangleGroupIcon, current: true, count: 0},
		{name: 'Move Notifications', slug: 'move-notifications', href: '/move-notifications', icon: ClipboardDocumentListIcon, current: false, count: coos.count("global")},
		{
			name: 'Moves',
			slug: 'moves',
			href: '/moves',
			icon: ArrowsRightLeftIcon,
			current: false,
			count: session.can('can_view_pending_moves') ? moves.count("global") : 0
		},
		{name: 'Properties', slug: 'properties', href: '/properties', icon: HomeIcon, current: false, count: 0},
		{name: 'Movers', slug: 'movers', href: '/movers', icon: UserGroupIcon, current: false, count: 0},
	];
}

const navigation = useState("navigation", computeNavigation);

watch(() => coos.instances['global']?.totalHits, () => {
	navigation.value = computeNavigation();
});

watch(() => moves.instances['global']?.totalHits, () => {
	navigation.value = computeNavigation();
});

const isLoggingOut = ref(false);

const logout = () => {
	isLoggingOut.value = true;
	session.logout();
}

const searchRef = ref("");

const openBranchModal = () => {
	isBranchModalOpen.value = true;
};

const closeBranchModal = () => {
	isBranchModalOpen.value = false;
};

const openApiModal = () => {
	isApiModalOpen.value = true;
};

const closeApiModal = () => {
	isApiModalOpen.value = false;
};

const openPasswordModal = () => {
	isPasswordModalOpen.value = true;
};

const hasMultipleBranches = computed(() => {
	return session.user && session.allowed_partners.length > 1;
});

const selectedBranch = computed(() => {
	if (session.user) {
		const selectedPartner = session.allowed_partners.filter((partner) => {
			return partner.id === session.activePartnerId;
		}).pop();

		return selectedPartner ? selectedPartner.label : "All Branches";
	} else {
		return "All Branches";
	}
});

const closePasswordModal = () => {
	// Check if the customer is forced to change their password
	if (!session.user?.force_password_change) {
		isPasswordModalOpen.value = false;
	}
};

const sidebarOpen = ref(false)

const closeMobileNav = () => {
	sidebarOpen.value = false;
	clearSearch();
};

// Disable eslint for this line because it's a runtime hook
// eslint-disable-next-line no-undef
definePageMeta({
	layoutTransition: {
		name: 'layout',
		mode: 'out-in'
	}
})

onMounted(() => {
	// Check if the customer is forced to change their password
	if (session.user?.force_password_change) {
		openPasswordModal();
	}

	// CRISP chat integration
	if (config.public["appEnv"] === 'production' ) {
		// tslint:disable-next-line: no-string-literal
		window.$crisp = [];
		window.CRISP_WEBSITE_ID = config.public["crispWebsiteId"];
		(function() {
			var d = document;
			var s = d.createElement("script");
			s.src = "https://client.crisp.chat/l.js";
			s.async = 1;
			d.getElementsByTagName("head")[0].appendChild(s);
		})();
		// Disable eslint because this is a third party script
		// eslint-disable-next-line no-undef
		$crisp.push(["do", "session:reset"]);
		// eslint-disable-next-line no-undef
		$crisp.push(["set", "user:email", [session.user?.email]]);
		// eslint-disable-next-line no-undef
		$crisp.push(["set", "user:phone", [session.user?.phone]]);
		// eslint-disable-next-line no-undef
		$crisp.push(["set", "user:nickname", [session.user?.full_name]]);
		// eslint-disable-next-line no-undef
		$crisp.push(["set", "user:company", [session.user?.partners[0]?.name ?? "Unknown Partner"]]);

		$crisp.push(["safe", true])
	}

	// Track the user last seen at if is authenticated
	session.lastSeenAtTracking();
});
</script>
