<template>
	<ModalsForm :open="open" title="Edit Password" :is-submitting="isSubmitting" @close="$emit('close')" @submit="onSubmit">
		<Alert
			v-if="displayAlert"
			class="pb-5"
			:message="alertMessage"
			:type="alertType"
			:can-close="true"
			@close="closeAlert"
		/>

		<FormsInput
			v-model="current_password"
			:required="true"
			label="Current password"
			name="current_password"
			type="password"
			input-class="pl-9"
		>
			<template #prefix>
				<div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
					<LockClosedIcon class="h-4 w-4 text-jmi-purple3" aria-hidden="true" />
				</div>
			</template>
		</FormsInput>
		<FormsInput
			v-model="new_password"
			class="mt-4"
			:required="true"
			label="New password"
			:schema="validation.new_password"
			name="new_password"
			type="password"
			input-class="pl-9"
		>
			<template #prefix>
				<div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
					<LockClosedIcon class="h-4 w-4 text-jmi-purple3" aria-hidden="true" />
				</div>
			</template>
		</FormsInput>
		<FormsInput
			v-model="new_password_confirmation"
			class="mt-4"
			:required="true"
			label="Confirm password"
			:schema="validation.new_password_confirmation"
			name="new_password_confirmation"
			type="password"
			input-class="pl-9"
		>
			<template #prefix>
				<div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
					<LockClosedIcon class="h-4 w-4 text-jmi-purple3" aria-hidden="true" />
				</div>
			</template>
		</FormsInput>
	</ModalsForm>
</template>

<script setup lang="ts">
import {ref, onMounted} from "vue";
import {LockClosedIcon} from '@heroicons/vue/24/outline';
import {useSession} from "../../stores/session";
import * as yup from "yup";

const session = useSession();

defineProps({
	open: {
		type: Boolean,
		required: true
	}
});

const subject = ref(null);
const current_password = ref(null);
const new_password = ref(null);
const new_password_confirmation = ref(null);
const isSubmitting = ref(false);

let displayAlert = ref(false);
let alertMessage = ref('');
let alertType = ref('success');

const closeAlert = async () => {
	displayAlert.value = false;
};

// Validation
const validation = computed(() => {
	return {
		new_password: yup.string().required().matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).*$/, 'The password must contain at least one lowercase letter, uppercase letter, number and special character'),
		new_password_confirmation: yup.string().oneOf([new_password.value, null], 'Password confirmation must be same as password').required()
	};
});

// Validation end

// Handle password submission
const onSubmit = async () => {
	isSubmitting.value = true;

	try {
		await session.editPassword(current_password.value, new_password.value, new_password_confirmation.value);

		isSubmitting.value = false;
		displayAlert.value = true;
		alertMessage.value = 'Your password has been changed.'
		alertType.value = 'success';

	} catch (error) {
		isSubmitting.value = false;
		// Reset the alert message
		alertMessage.value = '';
		// Check fo the 422 status code, if so means the password was invalid or validation failed
		if (error.response.status === 422) {
			for (const [errorKey, errorValue] of Object.entries(error.data.errors)) {
				for (const [key, error] of Object.entries(errorValue)) {
					alertMessage.value += '<p>' + error + '</p>';
				}
			}
		} else {
			alertMessage.value = 'Something went wrong.'
		}
		alertType.value = 'error';
		displayAlert.value = true;
	}
};

// Check if the customer is forced to change their password, if so display an alert to inform the customer
onMounted(() => {
	if (session.user?.force_password_change) {
		alertMessage.value = 'Please change your password to continue.'
		alertType.value = 'info';
		displayAlert.value = true;
	}
});

</script>
