<template>
	<div>
		<main v-if="modelValue" class="mb-20">
			<div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
				<div class="px-4 sm:px-0">
					<h1 class="text-2xl font-obviously-regular tracking-tighter">
						Search Results for '{{ modelValue }}'
					</h1>
					<div v-if="hasResults" class="mt-10 grid grid-cols-2 gap-6">
						<div v-if="properties.activeCount.search > 0">
							<h1 class="text-2xl font-obviously-regular tracking-tighter">
								Properties
							</h1>
							<PropertiesTable :store="properties" instance-label="search" :use-search="false" />
						</div>
						<div v-if="coos.activeCount.search > 0">
							<h1 class="text-2xl font-obviously-regular tracking-tighter">
								Move Notifications
							</h1>
							<MoveNotificationsTable :store="coos" instance-label="search" :use-search="false" />
						</div>
						<div v-if="movers.activeCount.search > 0">
							<h1 class="text-2xl font-obviously-regular tracking-tighter">
								Movers
							</h1>
							<MoversTable :store="movers" instance-label="search" :use-search="false" />
						</div>
						<div v-if="moves.activeCount.search > 0">
							<h1 class="text-2xl font-obviously-regular tracking-tighter">
								Moves
							</h1>
							<MovesTable :store="moves" instance-label="search" layout="small" :use-search="false" />
						</div>
					</div>
					<div v-else class="w-full my-56 mx-auto block">
						<NotAllowed
							title="No results found"
						/>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>
<script setup lang="ts">
import {watch, computed} from "vue";
import {useProperties} from "~/stores/properties";
import {useCoos} from "~/stores/coos";
import {useMovers} from "~/stores/movers";
import {useMoves} from "~/stores/moves";

const props = defineProps<{
	modelValue: string
}>();

const properties = useProperties();
const coos = useCoos();
const movers = useMovers();
const moves = useMoves();

properties.boot('search', {sort: {'address1':'asc'}, perPage: 10});
coos.boot('search', {sort: {'scheduled':'desc'}, perPage: 10});
movers.boot('search', {sort: {'firstname':'asc'}, perPage: 10});
moves.boot('search', {
	sort: {"movein.movedate":"desc"},
	perPage: 10
});

watch(() => props.modelValue, () => {
	let query = props.modelValue;

	properties.search('search', query);
	coos.search('search', query);
	movers.search('search', query);
	moves.search('search', query);
});

const hasResults = computed(() => {
	return properties.activeCount.search > 0 || coos.activeCount.search > 0 || movers.activeCount.search > 0 || moves.activeCount.search > 0;
});

</script>
