<template>
	<ModalsPlain :open="open" @close="$emit('close')">
		<div class="px-4 pt-5 pb-4 sm:p-6">
			<div class="bg-jmi-purple4 mx-auto flex items-center justify-center h-12 w-12 rounded-full">
				<CodeBracketIcon aria-hidden="true" class="h-6 w-6 text-jmi-purple1 stroke-2" />
			</div>
			<div class="mt-3 sm:mt-4">
				<DialogTitle as="h3" class="mb-4 text-lg text-center leading-6 font-medium text-jmi-purple1">
					API Keys
				</DialogTitle>

				<template v-if="session.partners_with_api_keys.length <= 0">
					<p class="mb-4 text-sm text-center jmi-purple1">
						To obtain your API key please contact Partner Success.
					</p>
				</template>
				<template v-else>
					<div v-for="partner in session.partners_with_api_keys" :key="partner.id" class="mt-2">
						<Tooltip>
							<FormsInput v-model="partner.api_key" :label="partner.name" :readonly="true" name="message" @click="copy">
								<template #suffix>
									<div class="absolute inset-y-0 right-2 pl-3 flex items-center pointer-events-none">
										<DocumentDuplicateIcon aria-hidden="true" class="h-4 w-4 text-jmi-purple3" />
									</div>
								</template>
							</FormsInput>

							<template #tooltip>
								{{ copyText }}
							</template>
						</Tooltip>
					</div>
				</template>
			</div>
		</div>
	</ModalsPlain>
</template>

<script setup lang="ts">
import {DialogTitle} from '@headlessui/vue';
import {CodeBracketIcon, DocumentDuplicateIcon} from '@heroicons/vue/24/outline';
import {ref, defineEmits, defineProps} from "vue";
import {useSession} from "~/stores/session";

const session = useSession();

defineProps({
	open: {
		type: Boolean,
		required: true
	}
});

defineEmits(["close"]);

const copyText = ref("Copy to Clipboard");

const copy = (event: MouseEvent) => {
	let target = event.target as HTMLInputElement;

	target.select();
	document.execCommand("copy");
	target.setSelectionRange(0, 0);
	copyText.value = "Copied!";
	setTimeout(() => {
		copyText.value = "Copy to Clipboard";
	}, 2000);
};

</script>
