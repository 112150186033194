<template>
	<PanelsDefault :open="movers.panelOpen" @close="movers.setPanel(false)">
		<template v-if="!movers.openingRecord">
			<div class="pb-1 sm:pb-6">
				<div>
					<div class="mt-6 px-4 sm:flex sm:items-end sm:px-6">
						<div class="sm:flex-1">
							<div>
								<div class="flex items-center">
									<h3 class="text-xl font-obviously-regular font-semibold tracking-tighter text-jmi-purple1 sm:text-2xl mb-2">
										{{ movers.currentRecord.full_name_and_title }}
									</h3>
								</div>
							</div>
							<div class="mt-6 flex flex-wrap space-y-3 sm:space-y-0 sm:space-x-3">
								<FormsButton dusk="move-button" color="green" @click.prevent="movers.userStartMove()">
									Start move
								</FormsButton>
								<FormsButton dusk="edit-button" color="greenOutline" @click.prevent="movers.userEdit()">
									<PencilIcon class="h-5 w-5 stroke-1" aria-hidden="true" />
								</FormsButton>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="px-4 pt-5 pb-5 sm:px-0 sm:pt-0">
				<dl class="space-y-8 sm:space-y-0 sm:divide-y sm:divide-jmi-purple4">
					<div v-for="item in items" class="sm:flex sm:px-6 sm:py-5">
						<dt class="text-sm font-medium text-jmi-purple2 sm:w-40 sm:flex-shrink-0 lg:w-48">
							{{ item.label }}
						</dt>
						<dd class="mt-1 text-sm text-jmi-purple1 sm:col-span-2 sm:mt-0 sm:ml-6">
							<div class="flex items-center justify-between">
								<p class="text-sm">
									{{ item.value }}
								</p>
							</div>
						</dd>
					</div>
				</dl>
			</div>
		</template>
		<template v-else>
			<Spinner class="w-24 my-56 mx-auto block" />
		</template>
	</PanelsDefault>
</template>
<script setup lang="ts">
import {
	PencilIcon,
} from '@heroicons/vue/24/solid';
import {useMovers} from "~/stores/movers";

const movers = useMovers();

const items = computed(() => {
	let items = [
		{
			label: "Email address",
			value: movers.currentRecord.email
		},
		{
			label: "Mobile number",
			value: movers.currentRecord.phone
		},
		{
			label: "Landline number",
			value: movers.currentRecord.altphone
		},
		{
			label: "Consent to contact mover",
			value: movers.currentRecord.gdpr_consent ? 'Yes' : 'No'
		},
	];

	return items.filter((item) => {
		return !!item.value;
	});
});

</script>
